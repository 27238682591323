<template>
  <router-view />
</template>

<script>
import Api from './services/Api'
import Utils from './services/Utils'
import moment from 'moment'

window.dashboardVersion = '2025-04-02 11:30'

export default {
  name: 'App',
  beforeMount: function () {
    window.language = 'pt'
    window.datepickerLanguage = 'pt-PT'
    Utils.clearStorage()
    this.checkOpenUrlNewPage()
    window.cachePhotos = {}
    window.diffUtcHours = this.getDiffUtcHours()
    window.isMobile = Utils.isMobile()
    window.strings = {}

    // Theme
    const cacheTheme = localStorage.getItem('dashboard_theme')
    this.$vuetify.theme.dark = cacheTheme !== 'light' ? true : false

    window.addEventListener('beforeunload', function () {
      if (window.chatPage && !window.chatPage.closed) {
        window.chatPage.close()
      }
      if (Utils.getUser()) {
        Api.logout(function () {})
      }
      Utils.clearStorage()
      return true
    })
  },
  methods: {
    checkOpenUrlNewPage: function () {
      try {
        if (localStorage.getItem('open_url_new_page')) {
          const data = JSON.parse(localStorage.getItem('open_url_new_page'))
          localStorage.removeItem('open_url_new_page')
          if (data && data.data) {
            window.urlToOpenAfterLogin = data.url
            window.dashboardSessionStorage = data.data
          }
        }
      } catch {}
    },
    getDiffUtcHours: function () {
      const format = 'YYYY-MM-DDTHH:mm:ss'
      const localTime = moment(moment().format(format), format)
      const utcTime = moment(moment.utc().format(format), format)
      return localTime.diff(utcTime, 'hours')
    },
  },
}
</script>
